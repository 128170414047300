"use client"
import Image from 'next/image'
import logo from "@/app/img/logo.png"
import { HiOutlineMenuAlt3 } from "react-icons/hi"
import AccountSection from './AccountSection';
import { useRouter } from 'next/navigation';

function Header({ background_color, account_section, student }) {
  const router = useRouter()
  const goHome = () => router.push('/');

  return (
    <main>
      {/* <nav className={`${background_color} fixed top-0 ease-in w-full z-50 dark:bg-gray-800 dark:border-gray-700 border-b-2`}> */}
      <nav className={`${background_color} fixed top-0 ease-in w-full z-50 border-b-2`}>
        <div className=' max-w-full flex items-center justify-between pl-2'>
          <div onClick={goHome} className='cursor-pointer'>
            {/* <Image src={logo} alt='logo' className='p-4 lg:w-52 lg:pl-14 sm:p-4' /> */}
            {/* <Image src={logo} alt='logo' className='p-4 lg:w-28 lg:pl-14 sm:p-4' /> */}
            <Image src={logo} alt='logo' className='p-2 w-20 h-20' />
          </div>
          {/* <HiOutlineMenuAlt3 className='lg:hidden block text-4xl ' /> */}

          <AccountSection className={account_section ? 'block' : 'hidden'} student={student} />
        </div>
      </nav>
      <div>
        {/* <Profile /> */}
      </div>
    </main>
  )
}

export default Header